import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

// redux
import { SliceName } from "./slice.types"

export const audienceSlice = createSlice({
  name: SliceName.audience,
  initialState: null,
  reducers: {
    setAudience: (s, action: PayloadAction<string>) => {
      return action.payload
    }
  }
})

export const { setAudience } = audienceSlice.actions

export const audienceReducer = audienceSlice.reducer
