import { Dispatch, AnyAction } from "redux"
import { navigate, withPrefix } from "gatsby"

// redux action(s)
import { setMetaData } from "../store/slices/metadata.slice"

type MicrocopySet = {
  microcopySetEntries: readonly any[]
}

export const LANG_CODES_TO_TLD = {
  "es-ar": ".com.ar",
  "en-au": ".com.au",
  "en-ca": ".ca",
  "en-gb": ".co.uk",
  "en-hk": ".hk",
  "en-ie": ".ie",
  "en-nz": ".co.nz",
  "en-sg": ".sg",
  "en-us": ".com",
  es: ".es",
  "es-mx": ".com.mx",
  "es-cl": ".cl",
  "es-co": ".co",
  "es-pe": ".com.pe",
  da: ".dk",
  fi: ".fi",
  sv: ".se",
  "pt-br": ".com.br",
  de: ".de",
  fr: ".fr",
  it: ".it",
  "nl-nl": ".nl",
  "pt-pt": ".pt",
  "de-ch": ".ch",
  "fr-ca": ".ca",
  "fr-ch": ".ch"
}

export type LANG_CODES = keyof typeof LANG_CODES_TO_TLD

export const isDevMode = (): boolean => {
  if (process.env && process.env.GATSBY_DEV_MODE === "true") {
    return true
  }

  if (typeof window !== "undefined") {
    if (window?.location?.host?.includes("localhost")) {
      return true
    }
  }

  return false
}

export const isLiveSupportedLocale = (
  supportedLocalesList: readonly string[],
  currentLocale: string
) => {
  return supportedLocalesList.includes(currentLocale.toLowerCase())
}

export const assignLangCode = (
  currentLangCode: string,
  dispatch: Dispatch<AnyAction>
) => {
  const urlLangCode = extractLangCode()

  if (!currentLangCode || urlLangCode !== currentLangCode) {
    dispatch(
      setMetaData({
        langCode: urlLangCode
      })
    )

    if (typeof document !== "undefined") {
      document.documentElement.lang = urlLangCode
    }
  }
}

// if you were on the url of https://eventbrite.com/help/en-us/topics/buy-and-register/ then this function would return "en-us"
// e.g. console.log(extractLangCode()); // "en-us"
export const extractLangCode = () => {
  if (typeof window !== "undefined") {
    const pathname = window.location.pathname
    const match =
      pathname.match(/\/help\/(\w{2}(?:-\w{2})?)\//) ||
      pathname.match(/\/help\/(\w{2}(?:-\w{2})?)$/) ||
      pathname.match(/\/(\w{2}(?:-\w{2})?)\//) ||
      pathname.match(/\/(\w{2}(?:-\w{2})?)$/)

    return match ? match[1] : ""
  }

  return "en-us"
}

// remove duplicate parts of the pathname
const filterUrl = (url: string, langCode: string) => {
  // replace any two or more consecutive slashes with a single slash
  const duplicateSlashFilteredUrl = `/${langCode}/${url}`.replace(
    /\/{2,}/g,
    "/"
  )
  const arr = duplicateSlashFilteredUrl.split("/") // split the string into an array of substrings
  const uniqueArr = [...new Set(arr)] // create a Set object to remove duplicates, then spread it back to an array
  const result = uniqueArr.join("/")

  return result
}

export const navigateWithPrefix: typeof navigate = (
  newUrlPath: string | number,
  navOptions = {},
  langLocale = "en-us"
) => {
  if (typeof window !== "undefined") {
    const langCode = extractLangCode() ?? langLocale

    // @ts-ignore
    return navigate(filterUrl(newUrlPath, langCode), navOptions)
  }
}

export const returnUrlWithPrefix = (newUrlPath: string) => {
  if (typeof window !== "undefined") {
    const langCode = extractLangCode()

    return withPrefix(filterUrl(newUrlPath, langCode))
  }
}

export const returnUrlWithLangCodePrefixOnly = (newUrlPath: string) => {
  if (typeof window !== "undefined") {
    const langCode = extractLangCode()

    return filterUrl(newUrlPath, langCode)
  }
}

export const getBaseUrl = () => {
  if (typeof window !== "undefined") {
    if (
      window?.location?.origin?.includes("localhost") ||
      window?.location?.origin?.includes("gatsbyjs.io")
    ) {
      return process.env.GATSBY_PRODUCT_SITE_URL
    }

    return window.location.origin
  }
}

export const withinBusinessHours = (timeZone: string, timeRange: string) => {
  if (timeRange?.toLowerCase()?.trim() === "24hrs") {
    return true
  }

  if (timeRange?.toLowerCase()?.trim() === "unavailable") {
    return false
  }

  // Split the time range string into start and end times
  const [startStr, endStr] = timeRange?.split("-")

  // Parse the start and end times as hours and minutes
  const [startHour, startMinute] = startStr?.split(":")?.map(Number)
  const [endHour, endMinute] = endStr?.split(":")?.map(Number)

  // Get the current date and time in the specified time zone
  const now = new Date().toLocaleString("en-us", { timeZone })

  // Convert the local time string to a Date object
  const localTime = new Date(now)

  // Set the start and end times of the range in the specified time zone
  const start = new Date(now)
  start.setHours(startHour, startMinute, 0, 0)
  const end = new Date(now)
  end.setHours(endHour, endMinute, 0, 0)

  // Check if the current time is within the range
  return localTime >= start && localTime <= end
}

export const getTheDay = (timeZone: string) => {
  const now = new Date()
  return now.toLocaleDateString("en-US", {
    weekday: "long",
    timeZone: timeZone
  })
}

export const generateMicrocopyObject = <T>(set: MicrocopySet): T => {
  const objMap = set?.microcopySetEntries?.map((entry) => Object.values(entry))
  return Object.fromEntries(objMap)
}

export const absoluteBaseUrlsFromLangCode = () => {
  const urls = {}

  Object.keys(LANG_CODES_TO_TLD).forEach((langCode) => {
    const lowerCasedLocale = langCode?.toLowerCase()
    const tld = LANG_CODES_TO_TLD[lowerCasedLocale]
    const baseHost = process.env.GATSBY_BASE_HOST_NAME?.replace(
      ".com",
      ""
    )?.trim()
    const finalBaseUrl = `https://www.${baseHost}${tld}/${process.env.GATSBY_PATH_PREFIX_VALUE}/`

    urls[lowerCasedLocale] = finalBaseUrl
  })

  return urls
}

/**
 * This does NOT include the langCode in the URL. E.G. passing in 'en-us' will return something like 'https://www.eventbrite.com/help/ while 'es' will return 'https://www.eventbrite.es/help/'
 * @param langCode
 * @returns
 */
export const fetchBaseUrlFromLangCode = (langCode: LANG_CODES | string) => {
  const tld = LANG_CODES_TO_TLD[langCode]?.toLowerCase()
  const absoluteUrls = absoluteBaseUrlsFromLangCode()

  return absoluteUrls[langCode]
}

export const isDisabled: (channel: string, disabledArr: string[]) => boolean = (
  channel: string,
  disabledArr: string[]
) => {
  return (
    disabledArr?.find(
      (x) => x.trim().toLowerCase() === channel.trim().toLowerCase()
    ) !== undefined
  )
}

export const isDateWithin24Hours = (eventUtcDate: Date) => {
  // Parse the `utc` value and get the current UTC time
  // const utcDate = new Date(data.utc);
  const now = new Date()

  // Calculate the difference in milliseconds
  const timeDifference = eventUtcDate.getTime() - now.getTime()

  // Convert milliseconds to hours
  const hoursDifference: number = timeDifference / (1000 * 60 * 60)

  // Check if the difference is within 24 hours
  const isWithin24Hours: boolean = hoursDifference <= 24 && hoursDifference >= 0

  return isWithin24Hours
}

export const isStringValidDate = (dateString: string): boolean => {
  const date = new Date(dateString)

  return !isNaN(date.getTime())
}
