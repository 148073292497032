import { configureStore, combineReducers } from "@reduxjs/toolkit"

// reducers
import { audienceReducer } from "./slices/audience.slice"
import { metaDataReducer } from "./slices/metadata.slice"
import { searchQueryReducer } from "./slices/search_query.slice"
import { userProfileReducer } from "./slices/user_profile.slice"
import { alertReducer } from "./slices/alert.slice"

// api services
import { helpArticlesApi } from "../services/help_articles.service"

const rootReducer = combineReducers({
  audience: audienceReducer,
  alert: alertReducer,
  searchQuery: searchQueryReducer,
  userProfile: userProfileReducer,
  metaData: metaDataReducer,
  [helpArticlesApi.reducerPath]: helpArticlesApi.reducer
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(helpArticlesApi.middleware)
})

export type RootState = ReturnType<typeof rootReducer>

export type AppDispatch = typeof store.dispatch
