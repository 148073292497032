import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

// types
import { SliceName } from "./slice.types"
import { InlineAlertProps } from "../../components/Marmalade/InlineAlert/InlineAlert"

export type AlertState = InlineAlertProps & {
  active: boolean
  description: string
}

const initState: AlertState = {
  active: false,
  variant: "info",
  description: "",
  title: "",
  closeTitle: "Close"
}

export const alertSlice = createSlice({
  name: SliceName.alert,
  initialState: initState,
  reducers: {
    setInlineAlertProps: (s, action: PayloadAction<AlertState>) => {
      return { ...s, ...action.payload }
    },
    setInlineAlertActivationStatus: (s, action: PayloadAction<boolean>) => {
      return {
        ...s,
        active: action.payload
      }
    }
  }
})

export const { setInlineAlertProps, setInlineAlertActivationStatus } =
  alertSlice.actions

export const alertReducer = alertSlice.reducer
