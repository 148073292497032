import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

// redux
import { SliceName } from "./slice.types"

export type BaseState = {
  query: string
}

export const searchQuerySlice = createSlice({
  name: SliceName.searchQuery,
  initialState: {
    query: ""
  },
  reducers: {
    setSearchQuery: (s: BaseState, action: PayloadAction<string>) => {
      return {
        ...s,
        query: action.payload
      }
    }
  }
})

export const { setSearchQuery } = searchQuerySlice.actions

export const searchQueryReducer = searchQuerySlice.reducer
