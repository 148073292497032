exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contentful-help-article-node-locale-articles-contentful-help-article-article-id-[slug]-tsx": () => import("./../../../src/pages/{ContentfulHelpArticle.node_locale}/articles/{ContentfulHelpArticle.articleId}/[slug].tsx" /* webpackChunkName: "component---src-pages-contentful-help-article-node-locale-articles-contentful-help-article-article-id-[slug]-tsx" */),
  "component---src-pages-contentful-help-article-node-locale-contact-us-tsx": () => import("./../../../src/pages/{ContentfulHelpArticle.node_locale}/contact-us.tsx" /* webpackChunkName: "component---src-pages-contentful-help-article-node-locale-contact-us-tsx" */),
  "component---src-pages-contentful-help-article-node-locale-index-tsx": () => import("./../../../src/pages/{ContentfulHelpArticle.node_locale}/index.tsx" /* webpackChunkName: "component---src-pages-contentful-help-article-node-locale-index-tsx" */),
  "component---src-pages-contentful-help-article-node-locale-search-tsx": () => import("./../../../src/pages/{ContentfulHelpArticle.node_locale}/search.tsx" /* webpackChunkName: "component---src-pages-contentful-help-article-node-locale-search-tsx" */),
  "component---src-pages-translation-error-tsx": () => import("./../../../src/pages/translationError.tsx" /* webpackChunkName: "component---src-pages-translation-error-tsx" */),
  "component---src-templates-category-pages-tsx": () => import("./../../../src/templates/CategoryPages.tsx" /* webpackChunkName: "component---src-templates-category-pages-tsx" */)
}

